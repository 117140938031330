import { AnyAction, combineReducers } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';

import reviews, { ReviewsState } from './universal/reviews';
import digitalGuidanceStampRally, {
  DigitalGuidanceStampRallyState,
} from 'ducks/universal/digitalGuidanceStampRally';

export type UniversalState = {
  reviews: ReviewsState;
  digitalGuidanceStampRally: DigitalGuidanceStampRallyState;
};

const reducer = combineReducers({
  reviews,
  digitalGuidanceStampRally,
});

const universal = (state: UniversalState, action: AnyAction) => {
  switch (action.type) {
    case HYDRATE:
      return {
        ...action.payload.universal,
      };
    default:
      return reducer(state, action);
  }
};

export default universal;
