import * as React from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { logCustomerEvent } from 'ducks/client/customerEvents';
import { ReduxState } from 'ducks';
import { useTranslation } from 'react-i18next';

interface Props {
  apiKey: string;
}

export const AnalyticsPageEventTracker = ({ apiKey }: Props) => {
  const scrolledEvents = React.useRef<number[]>([]);

  const dispatch = useDispatch();

  const { i18n } = useTranslation();

  const router = useRouter();

  const visitorToken = useSelector((state: ReduxState) => state.tracking.visitorToken);
  const reservation = useSelector((state: ReduxState) => state.server.reservation.reservation);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      // Wait for document title to be set before sending page view event. 200 ms is arbitrary.
      setTimeout(() => {
        const metadata: Record<string, string> = {
          page: router.asPath,
          page_title: document.title,
          lang: i18n.language,
          host: window.location.hostname,
        };

        const savedReferrer = sessionStorage.getItem('landingReferrer') || '';
        const referrer = savedReferrer || document.referrer;

        if (referrer && !referrer.includes(window.location.hostname)) {
          metadata['referrer'] = referrer;
        }

        dispatch(
          logCustomerEvent({
            apiKey,
            eventType: 'page-view',
            visitorToken,
            reservationId: reservation?.id ?? '',
            metadata: JSON.stringify(metadata),
          })
        );
      }, 200);

      document.onvisibilitychange = () => {
        if (document.visibilityState === 'hidden') {
          dispatch(
            logCustomerEvent({
              apiKey,
              eventType: 'leave-page',
              visitorToken,
              reservationId: reservation?.id ?? '',
              metadata: JSON.stringify({
                page: router.asPath,
                page_title: document.title,
                lang: i18n.language,
              }),
            })
          );
        }
        if (document.visibilityState === 'visible') {
          dispatch(
            logCustomerEvent({
              apiKey,
              eventType: 'return-page',
              visitorToken,
              reservationId: reservation?.id ?? '',
              metadata: JSON.stringify({
                page: router.asPath,
                page_title: document.title,
                lang: i18n.language,
              }),
            })
          );
        }
      };

      window.onscroll = () => {
        const scrollTop = window.scrollY;
        const docHeight = document.body.offsetHeight;
        const winHeight = window.innerHeight;
        const scrollPercent = scrollTop / (docHeight - winHeight);

        [75].forEach((percent) => {
          if (scrollPercent > percent / 100) {
            if (!scrolledEvents.current?.includes(percent)) {
              dispatch(
                logCustomerEvent({
                  apiKey,
                  eventType: 'scroll-75',
                  visitorToken,
                  reservationId: reservation?.id ?? '',
                  metadata: JSON.stringify({
                    page: router.asPath,
                    page_title: document.title,
                    lang: i18n.language,
                  }),
                })
              );
              scrolledEvents.current.push(percent);
            }
          }
        });
      };
    }
  }, [router, apiKey, dispatch, visitorToken, reservation, i18n.language]);

  return null;
};
