import { Notification } from 'models/notification';
import { createAction } from 'ducks/actionHelpers';
import { combineReducers } from 'redux';

const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

let idCounter = 1;
export const addNotification = (notification: Notification) =>
  createAction(ADD_NOTIFICATION, notification);
export const removeNotification = (id: string) => createAction(REMOVE_NOTIFICATION, id);

type Action = ReturnType<typeof addNotification> | ReturnType<typeof removeNotification>;

export const marketingAutomationNotifications = (
  state: Notification[] = [],
  action: Action
): Notification[] => {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      if (action.payload.type !== 'MARKETING_AUTOMATION') return state;

      const newState = [
        ...state,
        {
          ...action.payload,
          id: `${idCounter}`,
        },
      ];
      idCounter++;
      return newState;
    }

    case REMOVE_NOTIFICATION:
      return state.filter((notification) => notification.id !== action.payload);

    default:
      return state;
  }
};

export const restaurantOrderNotifications = (
  state: Notification[] = [],
  action: Action
): Notification[] => {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      if (action.payload.type !== 'RESTAURANT_ORDER') return state;

      const newState = [
        ...state,
        {
          ...action.payload,
          id: `${idCounter}`,
        },
      ];
      idCounter++;
      return newState;
    }

    case REMOVE_NOTIFICATION:
      return state.filter((notification) => notification.id !== action.payload);

    default:
      return state;
  }
};

export interface NotificationState {
  marketingAutomationNotifications: Notification[];
  restaurantOrderNotifications: Notification[];
}

export default combineReducers({
  marketingAutomationNotifications,
  restaurantOrderNotifications,
});
