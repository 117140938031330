import { AnyAction, combineReducers } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';

import settings, { fetchSettings, SettingsState } from 'ducks/server/settings';
import product, { fetchProduct, ProductState } from 'ducks/server/product';
import productInstance, {
  fetchProductInstance,
  ProductInstanceState,
} from 'ducks/server/productInstance';
import productSummaries, {
  fetchProductSummaries,
  ProductSummariesState,
} from 'ducks/server/productSummaries';
import reservation, { fetchReservation, ReservationState } from 'ducks/server/reservation';
import promotion, { fetchPromotion, PromotionState } from 'ducks/server/promotion';
import customPages, { fetchCustomPages, CustomPagesState } from 'ducks/server/customPages';
import waiverTemplates, {
  fetchWaiverTemplateForReservation,
  fetchWaiverTemplate,
  WaiverTemplatesState,
} from 'ducks/server/waiverTemplates';
import waivers, { fetchWaiver, fetchWaivers, WaiversState } from 'ducks/server/waivers';
import reservationVoucher, {
  fetchReservationVoucher,
  ReservationVoucherState,
} from 'ducks/server/reservationVoucher';
import mediaDownloadSalesPage, {
  fetchMediaDownloadSalesPageForReservation,
  MediaDownloadSalesPageState,
} from 'ducks/server/mediaDownloadSalesPage';
import mediaDownloadOrder, {
  fetchMediaDownloadOrder,
  MediaDownloadOrderState,
} from 'ducks/server/mediaDownloadOrder';
import digitalGuidancePage, {
  fetchDigitalGuidancePage,
  DigitalGuidancePageState,
} from 'ducks/server/digitalGuidancePage';
import reservationDigitalGuidancePages, {
  fetchReservationDigitalGuidancePages,
  ReservationDigitalGuidancePagesState,
} from 'ducks/server/reservationDigitalGuidancePages';
import surveyTemplates, {
  fetchSurveyTemplate,
  fetchReservationSurveyTemplate,
  SurveyTemplatesState,
} from 'ducks/server/surveyTemplates';
import reservationDigitalGuidanceStampRallies, {
  fetchReservationDigitalGuidanceStampRallies,
  ReservationDigitalGuidanceStampRalliesState,
} from 'ducks/server/reservationDigitalGuidanceStampRallies';
import eTicketQrCode, { fetchETicketQrCode, ETicketQrCodeState } from 'ducks/server/eTicketQrCode';
import customTopPage, { fetchCustomTopPage, CustomTopPageState } from 'ducks/server/customTopPage';
import groupBookingTemplate, {
  fetchGroupBookingTemplate,
  GroupBookingTemplateState,
} from 'ducks/server/groupBookingTemplate';
import reservationReceipt, {
  fetchReservationReceipt,
  ReservationReceiptState,
} from 'ducks/server/reservationReceipt';
import equipmentInstances, {
  fetchEquipmentInstances,
  EquipmentInstancesState,
} from 'ducks/server/equipmentInstances';
import digitalMap, { fetchDigitalMap, DigitalMapState } from 'ducks/server/digitalMap';
import instantWin, { fetchInstantWin, InstantWinState } from 'ducks/server/instantWin';
import instantWinEvent, {
  fetchInstantWinEvent,
  InstantWinEventState,
} from 'ducks/server/instantWinEvent';
import partnershipProductSummaries, {
  fetchPartnershipProductSummaries,
  PartnershipProductSummariesState,
} from 'ducks/server/partnershipProductSummaries';

export type ServerState = {
  settings: SettingsState;
  productSummaries: ProductSummariesState;
  product: ProductState;
  productInstance: ProductInstanceState;
  reservation: ReservationState;
  reservationReceipt: ReservationReceiptState;
  reservationDigitalGuidancePages: ReservationDigitalGuidancePagesState;
  promotion: PromotionState;
  customPages: CustomPagesState;
  customTopPage: CustomTopPageState;
  waiverTemplates: WaiverTemplatesState;
  waivers: WaiversState;
  reservationVoucher: ReservationVoucherState;
  mediaDownloadSalesPage: MediaDownloadSalesPageState;
  mediaDownloadOrder: MediaDownloadOrderState;
  digitalGuidancePage: DigitalGuidancePageState;
  surveyTemplates: SurveyTemplatesState;
  reservationDigitalGuidanceStampRallies: ReservationDigitalGuidanceStampRalliesState;
  eTicketQrCode: ETicketQrCodeState;
  groupBookingTemplate: GroupBookingTemplateState;
  equipmentInstances: EquipmentInstancesState;
  digitalMap: DigitalMapState;
  instantWin: InstantWinState;
  instantWinEvent: InstantWinEventState;
  partnershipProductSummaries: PartnershipProductSummariesState;
};

// Alias server side fetches so callers can be explicit
export const fetchSettingsServerSide = fetchSettings;
export const fetchProductSummariesServerSide = fetchProductSummaries;
export const fetchProductServerSide = fetchProduct;
export const fetchProductInstanceServerSide = fetchProductInstance;
export const fetchReservationServerSide = fetchReservation;
export const fetchReservationReceiptServerSide = fetchReservationReceipt;
export const fetchReservationDigitalGuidancePagesServerSide = fetchReservationDigitalGuidancePages;
export const fetchPromotionServerSide = fetchPromotion;
export const fetchCustomPagesServerSide = fetchCustomPages;
export const fetchCustomTopPageServerSide = fetchCustomTopPage;
export const fetchWaiverTemplateForReservationServerSide = fetchWaiverTemplateForReservation;
export const fetchWaiverTemplateServerSide = fetchWaiverTemplate;
export const fetchWaiverServerSide = fetchWaiver;
export const fetchWaiversServerSide = fetchWaivers;
export const fetchReservationVoucherServerSide = fetchReservationVoucher;
export const fetchMediaDownloadSalesPageForReservationServerSide = fetchMediaDownloadSalesPageForReservation;
export const fetchMediaDownloadOrderServerSide = fetchMediaDownloadOrder;
export const fetchDigitalGuidancePageServerSide = fetchDigitalGuidancePage;
export const fetchSurveyTemplateServerSide = fetchSurveyTemplate;
export const fetchReservationSurveyTemplateServerSide = fetchReservationSurveyTemplate;
export const fetchReservationDigitalGuidanceStampRalliesServerSide = fetchReservationDigitalGuidanceStampRallies;
export const fetchETicketQrCodeServerSide = fetchETicketQrCode;
export const fetchGroupBookingTemplateServerSide = fetchGroupBookingTemplate;
export const fetchEquipmentInstancesServerSide = fetchEquipmentInstances;
export const fetchDigitalMapServerSide = fetchDigitalMap;
export const fetchInstantWinServerSide = fetchInstantWin;
export const fetchInstantWinEventServerSide = fetchInstantWinEvent;
export const fetchPartnershipProductSummariesServerSide = fetchPartnershipProductSummaries;

const reducer = combineReducers({
  digitalGuidancePage,
  mediaDownloadOrder,
  mediaDownloadSalesPage,
  product,
  productInstance,
  productSummaries,
  reservation,
  reservationReceipt,
  reservationDigitalGuidancePages,
  reservationVoucher,
  promotion,
  settings,
  customPages,
  customTopPage,
  surveyTemplates,
  waiverTemplates,
  waivers,
  reservationDigitalGuidanceStampRallies,
  eTicketQrCode,
  groupBookingTemplate,
  equipmentInstances,
  digitalMap,
  instantWin,
  instantWinEvent,
  partnershipProductSummaries,
});

const server = (state: ServerState, action: AnyAction) => {
  switch (action.type) {
    case HYDRATE:
      return {
        ...action.payload.server,
      };
    default:
      return reducer(state, action);
  }
};

export default server;
