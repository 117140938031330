import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { createAction } from 'ducks/actionHelpers';
import { ReduxState } from 'ducks';
import { loadSessionToken } from './tracking';

// Actions
const LOG_CUSTOMER_EVENT_REQUEST = 'LOG_CUSTOMER_EVENT_REQUEST';
const LOG_CUSTOMER_EVENT_SUCCESS = 'LOG_CUSTOMER_EVENT_SUCCESS';
const LOG_CUSTOMER_EVENT_FAILURE = 'LOG_CUSTOMER_EVENT_FAILURE';

// Action creators
const logCustomerEventRequest = () => createAction(LOG_CUSTOMER_EVENT_REQUEST);
const logCustomerEventSuccess = () => createAction(LOG_CUSTOMER_EVENT_SUCCESS);
const logCustomerEventFailure = (payload: string) =>
  createAction(LOG_CUSTOMER_EVENT_FAILURE, payload);

export type EventType =
  | 'page-view'
  | 'leave-page'
  | 'return-page'
  | 'scroll-50'
  | 'scroll-75'
  | 'scroll-99'
  | 'reservation-thank-you'
  | 'check-availability'
  | 'begin-checkout'
  | 'eticket-before-redemption'
  | 'eticket-after-redemption'
  | 'location'
  | 'online-guide-after-coupon-redemption'
  | 'coupon-view'
  | 'pin-view'
  | 'eticket-view'
  | 'eticket-footprint'
  | 'eticket-redeem';

export const logCustomerEvent = ({
  apiKey,
  eventType,
  visitorToken,
  reservationId,
  metadata,
}: {
  apiKey: string;
  eventType: EventType;
  visitorToken: string;
  reservationId?: string;
  metadata?: string;
}) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>,
  getState: () => ReduxState
): Promise<void> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const customerId = getState().customer.customer?.id;

  dispatch(logCustomerEventRequest());

  return logCustomerEventWithNoSideEffects({
    apiKey,
    eventType,
    visitorToken,
    reservationId,
    customerId,
    metadata,
  })
    .then(() => {
      dispatch(logCustomerEventSuccess());
    })
    .catch((err) => {
      dispatch(logCustomerEventFailure(err.message));
    });
};

export const logCustomerEventWithNoSideEffects = ({
  apiKey,
  eventType,
  visitorToken,
  reservationId,
  metadata,
  customerId,
}: {
  apiKey: string;
  eventType: EventType;
  visitorToken: string;
  reservationId?: string;
  metadata?: string;
  customerId?: string;
}): Promise<void> => {
  return fetch(`${config.apiUrl}/customerevents`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': apiKey,
    },
    keepalive: true,
    body: JSON.stringify({
      event_type: eventType,
      visitor_token: visitorToken,
      session_id: loadSessionToken(),
      customer_id: customerId,
      reservation_id: reservationId,
      metadata,
    }),
  }).then((response) => {
    if (!response.ok) {
      throw new Error('Failed to log customer event');
    }
  });
};
