import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { DigitalMap } from 'models/digitalMap';
import { createAction } from 'ducks/actionHelpers';

const FETCH_DIGITAL_MAP_REQUEST = 'FETCH_DIGITAL_MAP_REQUEST';
const FETCH_DIGITAL_MAP_SUCCESS = 'FETCH_DIGITAL_MAP_SUCCESS';
const FETCH_DIGITAL_MAP_FAILURE = 'FETCH_DIGITAL_MAP_FAILURE';

const fetchDigitalMapRequest = () => createAction(FETCH_DIGITAL_MAP_REQUEST);
const fetchDigitalMapSuccess = (response: DigitalMap) =>
  createAction(FETCH_DIGITAL_MAP_SUCCESS, response);
const fetchDigitalMapFailure = (err: string) => createAction(FETCH_DIGITAL_MAP_FAILURE, err);

export const fetchDigitalMap = (
  apiKey: string,
  id: string,
  contentLanguage: string,
  reservationId?: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchDigitalMapRequest());

  return axios
    .get(`${config.apiUrl}/digitalmaps/${id}`, {
      params: reservationId ? { reservation_id: reservationId } : {},
      headers: {
        'x-api-key': apiKey,
        'accept-language': contentLanguage,
      },
    })
    .then((response) => {
      const payload = response.data;
      dispatch(fetchDigitalMapSuccess(payload));
    })
    .catch((err) => {
      dispatch(fetchDigitalMapFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof fetchDigitalMapRequest>
  | ReturnType<typeof fetchDigitalMapSuccess>
  | ReturnType<typeof fetchDigitalMapFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case FETCH_DIGITAL_MAP_FAILURE:
      return action.payload;
    case FETCH_DIGITAL_MAP_REQUEST:
    case FETCH_DIGITAL_MAP_SUCCESS:
      return '';
    default:
      return state;
  }
};

const addPinIndices = (map: DigitalMap) => {
  const pins = map.pins.map((pin, index) => ({ ...pin, index }));
  return { ...map, pins };
};

const map = (state: DigitalMap | null = null, action: Action) => {
  switch (action.type) {
    case FETCH_DIGITAL_MAP_SUCCESS:
      return addPinIndices(action.payload);
    case FETCH_DIGITAL_MAP_FAILURE:
      return null;
    default:
      return state;
  }
};

export interface DigitalMapState {
  error: ReturnType<typeof error>;
  map: ReturnType<typeof map>;
}

export default combineReducers({
  error,
  map,
});
