import axios from 'axios';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { ListProductsResponse, ProductSummary } from '../../models/product';
import { createAction } from '../actionHelpers';

// Actions
const PARTNERSHIP_PRODUCT_SUMMARIES_REQUEST = 'PARTNERSHIP_PRODUCT_SUMMARIES_REQUEST';
const PARTNERSHIP_PRODUCT_SUMMARIES_SUCCESS = 'PARTNERSHIP_PRODUCT_SUMMARIES_SUCCESS';
const PARTNERSHIP_PRODUCT_SUMMARIES_FAILURE = 'PARTNERSHIP_PRODUCT_SUMMARIES_FAILURE';

// Action creators
const partnershipProductSummariesRequest = () =>
  createAction(PARTNERSHIP_PRODUCT_SUMMARIES_REQUEST);
const partnershipProductSummariesSuccess = (response: ListProductsResponse) =>
  createAction(PARTNERSHIP_PRODUCT_SUMMARIES_SUCCESS, response);
const partnershipProductSummariesFailure = (err: string) =>
  createAction(PARTNERSHIP_PRODUCT_SUMMARIES_FAILURE, err);

export const fetchPartnershipProductSummaries = (
  apiKey: string,
  contentLanguage: string,
  includePrices = false,
  ignoreBlacklistedLanguages = false
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(partnershipProductSummariesRequest());
  return axios
    .get(`${config.apiUrl}/products`, {
      params: {
        ignore_blacklisted_languages: ignoreBlacklistedLanguages,
        include_prices: includePrices,
      },
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(partnershipProductSummariesSuccess(response.data));
    })
    .catch((err) => {
      dispatch(partnershipProductSummariesFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof partnershipProductSummariesRequest>
  | ReturnType<typeof partnershipProductSummariesSuccess>
  | ReturnType<typeof partnershipProductSummariesFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case PARTNERSHIP_PRODUCT_SUMMARIES_FAILURE:
      return action.payload;
    case PARTNERSHIP_PRODUCT_SUMMARIES_REQUEST:
    case PARTNERSHIP_PRODUCT_SUMMARIES_SUCCESS:
      return '';
    default:
      return state;
  }
};

const all = (state: ProductSummary[] = [], action: Action) => {
  switch (action.type) {
    case PARTNERSHIP_PRODUCT_SUMMARIES_SUCCESS:
      return action.payload.products;
    case PARTNERSHIP_PRODUCT_SUMMARIES_REQUEST:
    case PARTNERSHIP_PRODUCT_SUMMARIES_FAILURE:
      return [];
    default:
      return state;
  }
};

export interface PartnershipProductSummariesState {
  error: ReturnType<typeof error>;
  all: ReturnType<typeof all>;
}

export default combineReducers({
  error,
  all,
});
